import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ActionSheet from 'components/ActionSheet'
// import { Form } from 'formik'
import Button from 'components/button/Button'
import { Modal } from 'components/Modal'

// ////////////////////////////////////////
// ////////////////////////////////////////

const PLACEHOLDER_ORDER_DATA = [
  {
    type: 'FMC',
    data: [
      {
        product: 'Product Name A 10x20',
        qty: 20
      },
      {
        product: 'Product Name B 8x25',
        qty: 20
      },
      {
        product: 'Product Name C 8x25',
        qty: 20
      },
      {
        product: 'Product Name D 8x25',
        qty: 20
      },
      {
        product: 'Product Name E 8x25',
        qty: 20
      }
    ]
  },
  {
    type: 'VAPE',
    data: [
      {
        product: 'Product Name A 10x20',
        qty: 20
      },
      {
        product: 'Product Name B 8x25',
        qty: 20
      },
      {
        product: 'Product Name C 8x25',
        qty: 20
      },
      {
        product: 'Product Name D 8x25',
        qty: 20
      },
      {
        product: 'Product Name E 8x25',
        qty: 20
      }
    ]
  }
]

// ////////////////////////////////////////
// ////////////////////////////////////////

const PlaceOrder = () => {
  const navigate = useNavigate()

  const [summaryOpen, setSummaryOpen] = useState(false)
  const [checkoutOpen, setCheckoutOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleCheckout = () => {
    setSummaryOpen(false)
    setCheckoutOpen(true)
  }

  const handleCompleteOrder = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
      setCheckoutOpen(false)
      navigate('..')
    }, 1000)
  }

  const FooterContent = () => (
    <div className="flex w-full justify-end">
      <div className="flex shrink-0 gap-3">
        <Button secondary onClick={() => setSummaryOpen(false)}>
          Go Back
        </Button>
        <Button primary endIcon="right-arrow" onClick={handleCheckout}>
          Checkout
        </Button>
      </div>
    </div>
  )

  return (
    <>
      <Modal
        trigger={
          <Button primary endIcon="right-arrow">
            Checkout
          </Button>
        }
        title="Cart Summary"
        subtitle="Make sure you have everything before you checkout"
        footer={<FooterContent />}
        open={summaryOpen}
        onOpenChange={setSummaryOpen}
      >
        <table className="w-full text-sm">
          <thead>
            <tr className="*:font-medium *:uppercase *:text-slate-500">
              <th>Product</th>
              <th className="text-right">Qty</th>
            </tr>
          </thead>
          <tbody>
            {PLACEHOLDER_ORDER_DATA.map((order) => {
              const totalQty = order.data.reduce((sum, item) => sum + item.qty, 0)

              return (
                <React.Fragment key={order.type}>
                  <tr className="*:pb-2 *:pt-4 *:font-semibold *:text-slate-900">
                    <td>{order.type}</td>
                    <td className="text-right">{totalQty}</td>
                  </tr>
                  {order.data.map((data) => (
                    <tr key={data.product} className="*:py-1 *:text-slate-700">
                      <td>{data.product}</td>
                      <td className="text-right">{data.qty}</td>
                    </tr>
                  ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </Modal>
      <ActionSheet
        title={'Complete Order'}
        visible={checkoutOpen}
        onOverlayClick={() => setCheckoutOpen(false)}
        action={<button onClick={() => setCheckoutOpen(false)}>Cancel</button>}
      >
        <div className="flex flex-col gap-4">
          <div>PO98 or SAQ order actions</div>
          {/* <Form>
      {globalError && (
        <FieldsetItem>
          <GlobalAlert>{globalError}</GlobalAlert>
        </FieldsetItem>
      )}
      {emailPromptVisible && isPO98Order && (
        <PO98ActionSheet
          customer={customer}
          onClose={() => {
            setEmailPromptVisible(false)
            navigate('..')
          }}
          submitting={isSubmitting}
          isMobileViewOnly={isMobileViewOnly}
          isEdit={isEdit}
          totalQuantity={totalQuantity}
        />
      )}
      {!isPO98Order && (
        <SAQActionSheet
          customer={customer}
          isEmergencyOrder={isEmergencyOrder}
          requiresApproval={requiresApproval}
          threshold={threshold}
          onClose={() => {
            setEmailPromptVisible(false)
            navigate('..')
          }}
          submitting={isSubmitting}
          isMobileViewOnly={isMobileViewOnly}
          showSecondEmergencyOrderWarning={showSecondEmergencyOrderWarning}
          isEdit={isEdit}
          totalQuantity={totalQuantity}
          qtyToRemove={qtyToRemove}
          visible={emailPromptVisible}
        />
      )}
    </Form> */}
          <div>
            <Button primary onClick={handleCompleteOrder} isLoading={isLoading}>
              Complete Order
            </Button>
          </div>
        </div>
      </ActionSheet>
    </>
  )
}

export default PlaceOrder
