import React, { useContext } from 'react'
import { number } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import Card from 'components/card'
import SuggestedAmounts from 'components/close/SuggestedAmounts'
import Status from 'components/Status'

const TotalOpportunitiesCard = ({ span }) => {
  const { translate } = useContext(LangContext)

  const cardProps = {
    title: translate('common.total'),
    span
  }

  return (
    <Card {...cardProps}>
      <SuggestedAmounts title="Total Suggested Amounts" qty={296} value={2468.4} />

      <div className="flex flex-col gap-2 font-medium">
        <p className="text-2xs uppercase text-slate-500 ">Suggested Amounts by Category</p>
        <div className="flex flex-col gap-3 rounded-md bg-slate-50 p-4 @container">
          <div className="flex items-center gap-4">
            <p className="w-full text-slate-700">FMC</p>
            <div className="flex shrink-0 flex-col gap-2 @[18rem]:flex-row @[18rem]:gap-4 ">
              <p className="text-right">148 units</p>
              <p className="text-right">${(1234.2).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
            </div>
          </div>
          <hr />
          <div className="flex items-center gap-4">
            <p className="w-full text-slate-700">VAPE</p>
            <div className="flex shrink-0 flex-col gap-2 @[18rem]:flex-row @[18rem]:gap-4 ">
              <p className="text-right">148 units</p>
              <p className="text-right">${(1234.2).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col items-center gap-4">
        <div>
          <Button primary icon="cart" to="./cart">
            Review Cart
          </Button>
        </div>
        <div className="flex w-full flex-col gap-2 font-medium">
          <p className="text-2xs uppercase text-slate-500 ">Last order</p>
          <table className="w-full min-w-full divide-y divide-slate-900/10 overflow-clip rounded-md ring-1 ring-slate-900/10">
            <thead className="bg-slate-50">
              <tr className="text-left text-sm font-medium text-slate-900">
                <th scope="col" className="p-3 pl-4 pt-4">
                  Date
                </th>
                <th scope="col" className="p-3 pt-4">
                  Qty
                </th>
                <th scope="col" className="p-3 pr-4 pt-4">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <tr className="whitespace-nowrap text-sm text-slate-700">
                <td className="p-3 pb-4 pl-4">Sep 19</td>
                <td className="p-3 pb-4 font-semibold">56</td>
                <td className="p-3 pb-4 pr-4">
                  <Status status="pending" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="gap0.5 flex flex-col rounded-md bg-slate-50 p-4 text-xs font-medium text-slate-700">
        <p>
          AWR13: <span className="font-semibold text-sky-600">27</span>
        </p>
        <p>
          WTD: <span className="font-semibold text-sky-600">0</span>
        </p>
        <p>
          <span className="font-semibold text-sky-600">40</span> cartons left before threshold
        </p>
        <p>
          <span className="font-semibold text-sky-600">13</span> cartons left to SAQ before threshold
        </p>
      </div>
    </Card>
  )
}

TotalOpportunitiesCard.propTypes = {
  span: number
}

export default TotalOpportunitiesCard
