import React, { useState } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { bool, node, number } from 'prop-types'

import Icon from 'components/Icon'

import CheckoutTable from './CheckoutTable'

/// /////////////////////
/// /////////////////////
const PLACEHOLDER_PRODUCTS = [
  {
    product: 'Product Name A 10x20',
    price: 15.1,
    top80: true,
    awr4: 60,
    recoQty: 20,
    qty: 0
  },
  {
    product: 'Product Name B 8x25',
    price: 15.0,
    top80: true,
    awr4: 60,
    recoQty: 20,
    qty: 0
  },
  {
    product: 'Product Name C 8x25',
    price: 15.0,
    top80: false,
    awr4: 60,
    recoQty: 20,
    qty: 0
  },
  {
    product: 'Product Name D 8x25',
    price: 15.0,
    top80: false,
    awr4: 60,
    recoQty: 20,
    qty: 0
  },
  {
    product: 'Product Name E 8x25',
    price: 15.0,
    top80: true,
    awr4: 60,
    recoQty: 20,
    qty: 0
  }
]
const PLACEHOLDER_DATA_FMC = [
  {
    name: 'DuMaurier',
    data: PLACEHOLDER_PRODUCTS.map((product) => ({ ...product })),
    drivers: {
      oos: '30% DM mitigation of OOS potential',
      seasonality: '50% Expected gain in offtake driven by seasonality/events (+10% avg. of last 3 years)',
      surge: '30% Rapid surge in Du Maurier SKUs (L4: +8%, L13: +5%)'
    }
  },
  {
    name: 'Pall Mall',
    data: PLACEHOLDER_PRODUCTS.map((product) => ({ ...product })),
    drivers: {
      oos: '30% PM mitigation of OOS potential',
      seasonality: '50% Expected gain in offtake driven by seasonality/events (+10% avg. of last 3 years)',
      surge: '30% Rapid surge in Pall Mall SKUs (L4: +8%, L13: +5%)'
    }
  },
  {
    name: 'John Player',
    data: PLACEHOLDER_PRODUCTS.map((product) => ({ ...product })),
    drivers: {
      oos: '30% JP mitigation of OOS potential',
      seasonality: '50% Expected gain in offtake driven by seasonality/events (+10% avg. of last 3 years)',
      surge: '30% Rapid surge in John Player SKUs (L4: +8%, L13: +5%)'
    }
  },
  {
    name: 'Marlboro',
    data: PLACEHOLDER_PRODUCTS.map((product) => ({ ...product })),
    drivers: {
      oos: '30% Marlboro mitigation of OOS potential',
      seasonality: '50% Expected gain in offtake driven by seasonality/events (+10% avg. of last 3 years)',
      surge: '30% Rapid surge in Marlboro SKUs (L4: +8%, L13: +5%)'
    }
  },
  {
    name: 'Viceroy',
    data: PLACEHOLDER_PRODUCTS.map((product) => ({ ...product })),
    drivers: {
      oos: '30% Viceroy mitigation of OOS potential',
      seasonality: '50% Expected gain in offtake driven by seasonality/events (+10% avg. of last 3 years)',
      surge: '30% Rapid surge in Viceroy SKUs (L4: +8%, L13: +5%)'
    }
  }
]

const PLACEHOLDER_DATA_VAPE = [
  {
    name: 'Brand Name A',
    data: PLACEHOLDER_PRODUCTS.map((product) => ({ ...product })),
    drivers: {
      oos: '30% mitigation of OOS potential',
      seasonality: '50% Expected gain in offtake driven by seasonality/events (+10% avg. of last 3 years)',
      surge: '30% Rapid surge in SKUs (L4: +8%, L13: +5%)'
    }
  },
  { name: 'Brand Name B', data: PLACEHOLDER_PRODUCTS.map((product) => ({ ...product })) },
  { name: 'Brand Name C', data: PLACEHOLDER_PRODUCTS.map((product) => ({ ...product })) }
]
/// /////////////////////
/// /////////////////////

const CategoryTrigger = React.forwardRef(({ title, open = 'false', suggestedAmounts, ...props }, forwardedRef) => (
  <Accordion.Trigger
    className="group flex w-full items-center gap-4 px-3 py-3 text-lg font-medium text-slate-900 md:py-4 md:pl-4 md:pr-5"
    {...props}
    ref={forwardedRef}
  >
    <div
      aria-hidden
      className="flex size-6 shrink-0 items-center justify-center rounded-full bg-slate-900/5 text-slate-700"
    >
      <Icon icon={open ? 'minus-small' : 'plus-small'} compact />
    </div>
    <div className="flex w-full items-center justify-between">
      <h2 className="font-semibold text-slate-700 group-hover:text-slate-900 group-focus:text-slate-900">{title}</h2>
      <div className="flex flex-col items-end gap-1">
        <p className="text-2xs uppercase leading-none text-slate-500">Suggested</p>
        <div className="flex items-center gap-1.5 text-sm text-sky-600">
          <p>{suggestedAmounts.qty} ctns</p>
          <div className="h-4 w-px bg-slate-900/10" />
          <p>${suggestedAmounts.value.toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
        </div>
      </div>
    </div>
  </Accordion.Trigger>
))

CategoryTrigger.displayName = 'CategoryTrigger'

CategoryTrigger.propTypes = {
  title: node.isRequired,
  open: bool,
  suggestedAmounts: {
    qty: number.isRequired,
    value: number.isRequired
  }
}

const CategoryContent = ({ children }) => (
  <Accordion.Content className="overflow-auto transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
    {children}
  </Accordion.Content>
)

CategoryContent.propTypes = {
  children: node.isRequired
}

const CheckoutAccordion = ({ data }) => {
  const [value, setValue] = useState('fmc')

  return (
    <Accordion.Root
      type="single"
      defaultValue="fmc"
      collapsible
      onValueChange={setValue}
      className="divide-y divide-slate-900/10 rounded-md bg-white ring-1 ring-slate-900/10"
    >
      <Accordion.Item value="fmc" className="divide-y divide-slate-900/10">
        <CategoryTrigger title="FMC" open={value === 'fmc'} suggestedAmounts={{ qty: '#', value: 0 }} />
        <CategoryContent>
          <CheckoutTable productData={PLACEHOLDER_DATA_FMC} />
        </CategoryContent>
      </Accordion.Item>

      <Accordion.Item value="vape" className="divide-y divide-slate-900/10">
        <CategoryTrigger title="VAPE" open={value === 'vape'} suggestedAmounts={{ qty: '#', value: 0 }} />
        <CategoryContent>
          <CheckoutTable productData={PLACEHOLDER_DATA_VAPE} />
        </CategoryContent>
      </Accordion.Item>
    </Accordion.Root>
  )
}

CheckoutAccordion.propTypes = {
  data: node.isRequired
}

export default CheckoutAccordion
