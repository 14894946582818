import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySellout } from 'store/Sellout/actions'

import SelloutL13TrendGraphCard from 'components/PaceSelloutL13TrendCard'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import AmplifySelloutBrandCard from './AmplifySelloutBrandCard'
import AmplifySelloutKACard from './AmplifySelloutKACard'
import AmplifySelloutSOMCard from './AmplifySelloutSOMCard'
import AmplifySelloutTableCard from './AmplifySelloutTableCard'
import SelloutHeader from './SelloutHeader'

const AmplifySelloutLayout = ({ fetchAmplifySellout }) => {
  const mounted = useRef(false)

  useEffect(() => {
    document.title = 'Amplify - Sell-Out'
  }, [])

  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)

  const [currentProportion, setProportion] = useState('absolute')
  const [currentChannel, setChannel] = useState('pos')
  const [currentMetric, setMetric] = useState('cost')
  const [currentVapeCategory, setVapeCategory] = useState('allConsumables')
  const [currentTimeDisplay, setTimeDisplay] = useState('rolling')

  const [error, setError] = useState()
  if (error) console.log(error)

  useLayoutEffect(() => {
    if (currentProductType !== 'vape' && currentChannel === 'specialty') setChannel('pos')
    if (currentProductType === 'vape' && currentChannel === 'specialty') setMetric('cost')
  }, [currentProductType, currentChannel])

  const dataType = useMemo(() => {
    if (currentMetric === 'cost' || currentChannel === 'specialty') return 'cost'
    // if (currentProductType === 'fmc' && currentProportion === 'absolute') return 'pack_qty'
    return 'qty'
  }, [currentProductType, currentMetric, currentProportion, currentChannel])

  const dataKey = useMemo(
    () =>
      createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_OUT.MAIN, {
        sectorType: selectedLevel,
        sectorId: currentSector[selectedLevel]?.id,
        productType: currentProductType,
        channel: currentChannel,
        dataType
      }),
    [currentSector, selectedLevel, currentProductType, currentChannel, dataType]
  )

  useEffect(() => {
    setError()
    const invalidProductTypeChannel = currentProductType !== 'vape' && currentChannel === 'specialty'
    if (selectedLevel && currentSector[selectedLevel]?.id && !invalidProductTypeChannel) {
      fetchAmplifySellout(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType,
          channel: currentChannel,
          dataType
        },
        dataKey
      ).catch((error) => {
        if (mounted.curent) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType, currentChannel, dataType, dataKey])
  const selloutDataLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const currentFilterValues = {
    currentChannel,
    currentTimeDisplay,
    currentMetric,
    currentProportion,
    currentVapeCategory,
    setChannel,
    dataType,
    dataKey,
    selloutDataLoading
  }
  if (!selectedLevel) return null

  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5 max-md:pt-0">
      <SelloutHeader
        parentHeader={translate('app.amplify')}
        header={translate('app.sellOut')}
        currentChannel={currentChannel}
        currentProportion={currentProportion}
        currentMetric={currentMetric}
        currentVapeCategory={currentVapeCategory}
        setChannel={setChannel}
        setProportion={setProportion}
        setMetric={setMetric}
        setVapeCategory={setVapeCategory}
        currentTimeDisplay={currentTimeDisplay}
        setTimeDisplay={setTimeDisplay}
      />
      <SelloutL13TrendGraphCard
        title={translate('app.trendedView')}
        span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }}
        {...currentFilterValues}
      />
      <AmplifySelloutSOMCard span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }} {...currentFilterValues} />
      <AmplifySelloutBrandCard span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }} {...currentFilterValues} />
      <AmplifySelloutKACard span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }} {...currentFilterValues} />
      <AmplifySelloutTableCard span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} {...currentFilterValues} />
    </div>
  )
}

AmplifySelloutLayout.propTypes = {
  fetchAmplifySellout: func.isRequired
}

export default connect(null, { fetchAmplifySellout })(AmplifySelloutLayout)
