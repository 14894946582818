import React, { useState } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { node, object, string } from 'prop-types'

import CloseRecommendation from 'components/close/CloseRecommendation'
import Icon from 'components/Icon'
import { Modal } from 'components/Modal'
import NumberInput from 'components/NumberInput'

import { cn } from 'utils/styling'

const DriversModal = ({ brandName, drivers }) => {
  const [driversModalOpen, setDriversModalOpen] = useState(false)

  return (
    <Modal
      trigger={
        <div
          className="flex size-6 items-center justify-center rounded-full text-slate-500 transition-colors duration-200 hover:bg-slate-900/10 hover:text-slate-900"
          onClick={(e) => {
            e.stopPropagation()
            setDriversModalOpen((prevState) => ({
              ...prevState,
              [brandName]: true
            }))
          }}
        >
          <Icon icon="info-outline" small />
        </div>
      }
      title="Drivers"
      subtitle="Drivers are based on historical data and are used to calculate the recommended quantity for each product."
      open={driversModalOpen[brandName]}
      onOpenChange={(isOpen) => {
        setDriversModalOpen((prevState) => ({
          ...prevState,
          [brandName]: isOpen
        }))
      }}
    >
      <div className="flex flex-col gap-3">
        {!drivers || Object.keys(drivers).length === 0 ? (
          <p className="rounded-md bg-slate-50 p-3 py-6 text-center text-sm text-slate-500">No drivers found</p>
        ) : null}
        {drivers && drivers.oos && <CloseRecommendation type="OOS" recommendation={drivers.oos} />}
        {drivers && drivers.seasonality && (
          <CloseRecommendation type="seasonality" recommendation={drivers.seasonality} />
        )}
        {drivers && drivers.surge && <CloseRecommendation type="surge" recommendation={drivers.surge} />}
      </div>
    </Modal>
  )
}

DriversModal.propTypes = {
  brandName: string.isRequired,
  drivers: object.isRequired
}

const CheckoutTable = ({ productData }) => {
  const [open, setOpen] = useState('FMC')
  const [data, setData] = useState(productData)

  const recoQtyPerType = data.reduce(
    (sum, brand) => sum + brand.data.reduce((sum, product) => sum + product.recoQty, 0),
    0
  )
  const totalQtyPerType = data.reduce(
    (sum, brand) => sum + brand.data.reduce((sum, product) => sum + product.qty, 0),
    0
  )
  const totalValuePerType = data.reduce(
    (sum, brand) => sum + brand.data.reduce((sum, product) => sum + product.qty * product.price, 0),
    0
  )

  const handleQtyChange = (brandIndex, productIndex, newQty) => {
    const updatedData = [...data]
    updatedData[brandIndex].data[productIndex].qty = newQty
    setData(updatedData)
  }

  const handleAccessibleKeyDown = (e, name) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      setOpen(name)
    }
  }

  return (
    <Accordion.Root type="single" collapsible onValueChange={setOpen} value={open} asChild>
      <table className="min-w-full table-fixed">
        <thead className="text-left">
          <tr className="h-6 bg-slate-50 text-2xs uppercase text-slate-500 *:whitespace-nowrap *:pr-4 *:font-medium">
            <th></th>
            <th>Product</th>
            <th>Top 80</th>
            <th>AWR4</th>
            <th>Reco. Qty</th>
            <th>Qty</th>
            <th className="text-right">Value</th>
          </tr>
        </thead>
        <tbody className="">
          {data.map((brand, brandIndex) => {
            const recoQtyPerBrand = brand.data.reduce((sum, product) => sum + product.recoQty, 0)
            const totalQtyPerBrand = brand.data.reduce((sum, product) => sum + product.qty, 0)
            const totalValuePerBrand = brand.data.reduce((sum, product) => sum + product.qty * product.price, 0)

            return (
              <Accordion.Item key={brand.name} value={brand.name} asChild>
                <>
                  <Accordion.Trigger className="group" onClick={() => setOpen(brand.name)} asChild>
                    <tr
                      tabIndex="0"
                      onClick={() => setOpen(brand.name)}
                      onKeyDown={(e) => handleAccessibleKeyDown(e, brand.name)}
                      className="h-10 cursor-pointer border-t font-medium text-slate-900 transition-colors duration-200 *:pr-4 hover:bg-slate-50"
                    >
                      <td className="size-6 rounded-tl-md pl-2 pr-3">
                        <div className="size-6 -rotate-90 text-slate-500 transition-transform group-data-[state=open]:rotate-0">
                          <Icon icon="down-chevron" />
                        </div>
                      </td>
                      <td className="flex h-10 min-w-56 items-center gap-1">
                        <span>{brand.name}</span>
                        <DriversModal brandName={brand.name} drivers={brand.drivers} />
                      </td>
                      <td className="text-slate-300">-</td>
                      <td className="text-slate-300">-</td>
                      <td className={cn('', recoQtyPerBrand === 0 ? 'text-slate-500' : 'text-slate-900')}>
                        {recoQtyPerBrand}
                      </td>
                      <td className={cn('min-w-20', totalQtyPerBrand === 0 ? 'text-slate-300' : 'text-slate-900')}>
                        {totalQtyPerBrand === 0 ? '-' : totalQtyPerBrand}
                      </td>
                      <td
                        className={cn(
                          'min-w-20 text-right',
                          totalValuePerBrand === 0 ? 'text-slate-300' : 'text-slate-900'
                        )}
                      >
                        {totalValuePerBrand === 0
                          ? '-'
                          : totalValuePerBrand.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            })}
                      </td>
                    </tr>
                  </Accordion.Trigger>
                  <Accordion.Content asChild className="group">
                    <>
                      <tr className="h-3 border-t" />
                      {brand.data.map((product, productIndex) => {
                        const rows = brand.data.length
                        const isLastRow = productIndex + 1 === rows

                        return (
                          <tr key={productIndex} id="sub-row" className={cn('h-8 *:pr-4', isLastRow && 'pb-4')}>
                            <td></td>
                            <td className="whitespace-nowrap text-slate-700">{product.product}</td>
                            <td className={cn(product.top80 ? 'text-slate-700' : 'text-slate-300')}>
                              {product.top80 ? 'Yes' : '-'}
                            </td>
                            <td className="text-slate-700">{product.awr4}</td>
                            <td className="flex h-8 items-center gap-0.5 text-slate-700">
                              <p>{product.recoQty}</p>
                              <div className="mb-0.5 size-5 ">
                                <div className={cn('text-accents-green', product.recoQty !== product.qty && 'hidden')}>
                                  <Icon icon="checkmark-small" compact />
                                </div>
                              </div>
                            </td>
                            <td className="!px-0">
                              <div className="max-w-fit">
                                <NumberInput
                                  value={product.qty}
                                  onChange={(newQty) => handleQtyChange(brandIndex, productIndex, newQty)}
                                  fadeZero
                                  size="compact"
                                  fieldWidth="small"
                                />
                              </div>
                            </td>
                            <td
                              className={cn(
                                'min-w-20 text-right',
                                product.qty === 0 ? 'text-slate-300' : 'text-slate-900'
                              )}
                            >
                              {product.qty === 0
                                ? '-'
                                : (product.qty * product.price).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                  })}
                            </td>
                          </tr>
                        )
                      })}
                      <tr className="h-3" />
                    </>
                  </Accordion.Content>
                </>
              </Accordion.Item>
            )
          })}
        </tbody>
        <thead className="text-left">
          <tr className="sticky h-10 border-t text-slate-900 *:whitespace-nowrap *:pr-4 *:font-medium">
            <th></th>
            <th>Total</th>
            <th className="text-slate-300">-</th>
            <th className="text-slate-300">-</th>
            <th className="flex h-10 items-center gap-0.5 text-slate-700">
              <p>{recoQtyPerType}</p>
              <div className="mb-0.5 size-5 ">
                <div className={cn('text-accents-green', recoQtyPerType !== totalQtyPerType && 'hidden')}>
                  <Icon icon="checkmark-small" compact />
                </div>
              </div>
            </th>
            <th className={cn(totalQtyPerType === 0 ? 'text-slate-500' : 'text-slate-900')}>{totalQtyPerType}</th>
            <th className={cn('min-w-20 text-right', totalValuePerType === 0 ? 'text-slate-500' : 'text-slate-900')}>
              {totalValuePerType.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </th>
          </tr>
        </thead>
      </table>
    </Accordion.Root>
  )
}

CheckoutTable.propTypes = {
  productData: node.isRequired
}

export default CheckoutTable
