import React, { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
// import maxBy from 'lodash/maxBy'
// import minBy from 'lodash/minBy'
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import theme from 'tailwindcss/colors'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { selloutL13TrendGraphData } from 'store/Sellout/selectors'

import Card from 'components/card'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import LoadingCard from 'components/LoadingCard'
import { WrappedSpinner } from 'components/Spinner'

import { CHANNEL_FILTERS, PACE_SELLOUT_MANUFACTURER_FILTERS } from 'utils/constants'
import { formatCompactNumber, formatCurrency, formatNumber, formatPercent } from 'utils/formatters'

function generateYAxis({ entries, dataFormat }) {
  if (entries.length && entries.some(({ data }) => data)) {
    return ['auto', 'auto']
  }
  if (dataFormat === 'percent') return [0, 100]
  return [0, 10000]
}

const FMC_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.fmc.pos
const VAPE_POS_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.pos
const VAPE_SPECIALTY_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.specialty
const NRT_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.nrt.pos

const formatterByDataType = {
  percent: (v) => formatPercent(v),
  cost: formatCurrency,
  qty: formatNumber
  // pack_qty: formatNumber
}

const CustomTooltip = ({ active, payload = [], label, dataFormat }) => {
  if (!active || !payload || !payload.length) return null

  const formatter = formatterByDataType[dataFormat] || formatNumber
  const content = `${formatter(payload[0]?.value)}\nWE: ${label}`

  if (active && payload && payload[0])
    return (
      <div>
        <div
          className="w-auto -translate-x-1/2 space-y-1 rounded bg-black/90 px-3 py-2 text-white"
          style={{ whiteSpace: 'pre' }}
        >
          {content}
        </div>
      </div>
    )
}

CustomTooltip.propTypes = {
  active: bool,
  payload: arrayOf(shape({ value: number })),
  label: string,
  dataFormat: string
}

const Controls = tw.div`w-full flex space-x-3`

const manufacturerByProductType = {
  fmc: FMC_OPTIONS,
  vape: VAPE_POS_OPTIONS,
  nrt: NRT_OPTIONS
}

export const SelloutL13TrendGraphCard = ({
  span,
  amplifyAction,
  title,
  currentChannel,
  setChannel,
  currentProportion,
  currentVapeCategory,
  error,
  dataType,
  dataKey,
  selloutDataLoading
}) => {
  const { currentProductType } = useContext(SectorContext)
  const [currentManufacturer, setCurrentManufacturer] = useState()

  const setDefaultManufacturerOptions = () => {
    const defaultManufacturerOptions = manufacturerByProductType[currentProductType]
    setCurrentManufacturer(defaultManufacturerOptions?.[0].value)
  }

  const getManufacturerOptions = (options) => {
    if (currentProportion !== 'share') return [{ label: 'All', value: 'all' }, ...options]
    else setDefaultManufacturerOptions()
    return options
  }

  useLayoutEffect(() => {
    setChannel('pos')
    setDefaultManufacturerOptions()
  }, [currentProductType])

  const MANUFACTURERS_DROPDOWN_OPTIONS = useMemo(() => {
    if (currentProductType === 'fmc') return getManufacturerOptions(FMC_OPTIONS)
    if (currentProductType === 'nrt') return getManufacturerOptions(NRT_OPTIONS)
    if (currentChannel === 'specialty') return getManufacturerOptions(VAPE_SPECIALTY_OPTIONS)

    return getManufacturerOptions(VAPE_POS_OPTIONS)
  }, [currentChannel, currentProductType, currentProportion])

  const selloutData = useSelector((state) => {
    return selloutL13TrendGraphData(state, {
      currentManufacturer,
      activeProductType: currentProductType,
      currentChannel,
      currentProportion,
      currentVapeCategory,
      dataType
    })
  })

  const dataFormat = currentProportion === 'share' ? 'percent' : dataType
  const yAxisFormatter =
    dataFormat === 'percent'
      ? (v) => formatPercent(v, { convertDecimal: false })
      : (v) => formatCompactNumber(v, { forceDecimal: true, decimalPlaces: 1 })

  if (error) {
    return <EmptyState title="An error occured" subtitle={error} />
  }

  const cardProps = {
    title,
    span,
    amplifyAction
  }

  const renderContent = () => {
    if (selloutDataLoading) {
      return <WrappedSpinner icon="spinner" />
    }
    if (isEmpty(selloutData)) {
      return <EmptyState title="No data" />
    }

    return (
      <ResponsiveContainer height="99%" width="99%" className="pt-8">
        <LineChart data={selloutData} margin={{ bottom: 32, left: 0, right: 10, top: 10 }}>
          <CartesianGrid stroke={theme.slate[300]} vertical={false} />

          <XAxis
            style={{
              fontSize: '12px'
            }}
            axisLine={false}
            dataKey="name"
            dy={12}
            stroke={theme.slate[500]}
            tickLine={false}
          />

          <YAxis
            domain={generateYAxis({ entries: selloutData, dataFormat })}
            interval="preserveStartEnd"
            style={{
              fontSize: '12px'
            }}
            axisLine={false}
            dx={-8}
            stroke={theme.slate[500]}
            tickLine={false}
            tickFormatter={yAxisFormatter}
          />

          <Tooltip
            content={<CustomTooltip dataFormat={dataFormat} />}
            wrapperStyle={{
              outline: 'none'
            }}
            animationDuration={300}
            allowEscapeViewBox={{ x: true }}
            cursor={false}
            offset={0}
          />

          <Line
            dataKey="data"
            strokeWidth={3}
            stroke="#69AD55"
            dot={{ stroke: '#69AD55', r: 1, strokeWidth: 4 }}
            activeDot={{ stroke: theme.white, strokeWidth: 4, r: 6 }}
            isAnimationActive={false}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    )
  }

  return (
    <Card {...cardProps}>
      <div className="flex h-[490px] w-full flex-col">
        <Controls>
          {currentProductType === 'vape' && amplifyAction && (
            <Dropdown
              onChange={(e) => setChannel(e.target.value)}
              value={currentChannel}
              options={CHANNEL_FILTERS}
              disabled={selloutDataLoading}
            />
          )}
          <Dropdown
            onChange={(e) => setCurrentManufacturer(e.target.value)}
            value={currentManufacturer}
            options={MANUFACTURERS_DROPDOWN_OPTIONS}
            disabled={selloutDataLoading}
          />
        </Controls>
        {renderContent()}
      </div>
      <LoadingCard dataKey={dataKey} />
    </Card>
  )
}

SelloutL13TrendGraphCard.propTypes = {
  span: object,
  amplifyAction: func,
  title: string,
  currentChannel: string,
  setChannel: func,
  currentProportion: string,
  error: string,
  dataType: string,
  dataKey: string,
  selloutDataLoading: bool,
  currentVapeCategory: string
}

export default SelloutL13TrendGraphCard
