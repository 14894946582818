import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import theme from 'tailwindcss/colors'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchTrendingActivities } from 'store/extraHub/actions'
import { getCurrentTrending } from 'store/extraHub/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import SegmentControl from 'components/SegmentControl'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const TooltipContainer = tw.div`w-48 -translate-x-1/2 space-y-1 rounded bg-black/90 px-3 py-2 text-white`
const DataTitle = tw.span`text-slate-300`
const Data = tw.span``

const CustomTooltip = ({ active, payload, view }) => {
  const { translate } = useContext(LangContext)
  const barName = translate('amplify.extra.activeActivity')
  const lineName = view === 'store' ? translate('app.storeCompleted') : translate('app.userCompleted')
  if (active && payload && payload[0])
    return (
      <div>
        <TooltipContainer>
          <div className="flex w-full justify-between">
            <DataTitle>{lineName}</DataTitle>
            <Data>{payload[0]?.payload?.amount}</Data>
          </div>{' '}
          <div className="flex w-full justify-between">
            <DataTitle>{barName}</DataTitle>
            <Data>{payload[0]?.payload?.activity}</Data>
          </div>
        </TooltipContainer>
      </div>
    )
}

CustomTooltip.propTypes = {
  active: bool,
  payload: arrayOf(shape({ value: number })),
  view: string
}

const Trended = ({ span, fetchTrendingActivities }) => {
  const { translate } = useContext(LangContext)
  const { sectorType, sectorId } = useParams()
  const [error, setError] = useState()
  const [view, setView] = useState('store')

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.TRENDING, { sectorType, sectorId })
  const isTrendingLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const data = useSelector((state) => getCurrentTrending(state))

  useEffect(() => {
    fetchTrendingActivities({ sectorType, sectorId, dataKey })
      .then(() => isMounted.current && setError())
      .catch((error) => isMounted.current && setError(error))
  }, [sectorType, sectorId])

  const getContent = () => {
    const barName = translate('amplify.extra.activeActivity')
    const lineName = view === 'store' ? translate('app.storeCompleted') : translate('app.userCompleted')
    const graphData = data[view]
    if (isTrendingLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    return (
      <div className="h-64 w-full pt-5">
        <ResponsiveContainer height="99%" width="99%">
          <ComposedChart data={graphData} margin={{ bottom: 0, left: 0, right: 10, top: 10 }}>
            <CartesianGrid stroke={theme.slate[300]} vertical={false} />
            <XAxis
              style={{
                fontSize: '12px'
              }}
              axisLine={false}
              dataKey="name"
              yAxisId="left"
              dy={10}
              stroke={theme.slate[500]}
              tickLine={false}
            />
            <Bar
              name={barName}
              radius={[10, 10, 0, 0]}
              dataKey="activity"
              barSize={10}
              fill="#4FAEE6"
              yAxisId="right"
              legendType="rect"
            />
            <Line
              name={lineName}
              dataKey="amount"
              strokeWidth={3}
              stroke="#1F4E9A"
              yAxisId="left"
              dot={false}
              activeDot={{ stroke: theme.white, strokeWidth: 4, r: 6 }}
              isAnimationActive={false}
              connectNulls={true}
            />
            <YAxis
              interval="preserveStartEnd"
              style={{
                fontSize: '12px'
              }}
              axisLine={false}
              yAxisId="right"
              domain={[0, 6]}
              orientation="right"
              stroke={theme.slate[500]}
              tickLine={false}
            />
            <YAxis
              interval="preserveStartEnd"
              style={{
                fontSize: '12px'
              }}
              axisLine={false}
              yAxisId="left"
              domain={[0, 6]}
              dx={-8}
              stroke={theme.slate[500]}
              tickLine={false}
            />
            <Tooltip
              content={<CustomTooltip view={view} />}
              wrapperStyle={{
                outline: 'none'
              }}
              animationDuration={300}
              allowEscapeViewBox={{ x: true }}
              cursor={false}
              offset={0}
            />
            <Legend iconType="circle" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }
  return (
    <Card
      title={translate('app.trendedView')}
      span={span}
      headerActions={[
        <SegmentControl
          key={0}
          name="View type"
          onChange={(e) => setView(e.target.value)}
          value={view}
          options={[
            {
              label: translate('app.geo.store'),
              value: 'store'
            },
            {
              label: translate('app.user'),
              value: 'user'
            }
          ]}
        />
      ]}
    >
      {getContent()}
    </Card>
  )
}

Trended.propTypes = {
  span: object,
  fetchTrendingActivities: func
}

export default connect(null, {
  fetchTrendingActivities
})(Trended)
