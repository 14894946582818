import React from 'react'
import { number } from 'prop-types'

import Button from 'components/button/Button'
import IconButton from 'components/button/IconButton'
import Card from 'components/card'
import CheckoutAccordion from 'components/close/CheckoutAccordion'
import PlaceOrder from 'components/close/PlaceOrder'
import WarningModal from 'components/Modal/WarningModal'

const CartCard = ({ span }) => {
  const HeaderActions = () => (
    <div className="flex w-full gap-3 max-md:justify-between">
      <div className="flex gap-3">
        <WarningModal
          title="Are you sure?"
          message="This will set all the quantities in the cart to 0. You can't undo this action."
          primaryAction={{
            label: 'Clear all',
            type: 'destructive',
            action: () => console.log('Clear')
          }}
          secondaryAction={{
            label: 'Cancel',
            action: () => console.log('Cancel')
          }}
          trigger={
            <>
              <div className="flex @md/card:hidden">
                <IconButton secondary icon="close" compact />
              </div>
              <div className="hidden @md/card:flex">
                <Button secondary icon="close">
                  Clear
                </Button>
              </div>
            </>
          }
        />
        <WarningModal
          title="Are you sure?"
          message="This will reset the quantities in the cart to what they were before you made any changes. You can't undo this action."
          primaryAction={{
            label: 'Reset all',
            type: 'destructive',
            action: () => console.log('Reset')
          }}
          secondaryAction={{
            label: 'Cancel',
            action: () => console.log('Cancel')
          }}
          trigger={
            <>
              <div className="flex @md/card:hidden">
                <IconButton secondary icon="undo" compact />
              </div>
              <div className="hidden @md/card:flex">
                <Button secondary icon="undo">
                  Reset
                </Button>
              </div>
            </>
          }
        />
      </div>
      <div className="h-9 w-px shrink-0 bg-slate-100 max-md:hidden" />
      <WarningModal
        title="Are you sure?"
        message="This will overwrite the current cart with the previous order. You can't undo this action."
        primaryAction={{
          label: 'Copy Previous Order',
          action: () => console.log('Reset')
        }}
        secondaryAction={{
          label: 'Cancel',
          action: () => console.log('Cancel')
        }}
        trigger={
          <Button secondary icon="file-copy">
            Copy Previous Order
          </Button>
        }
      />
    </div>
  )

  const cardProps = {
    title: 'Order Cart',
    span,
    headerActions: [<HeaderActions key={1} />]
  }

  return (
    <Card {...cardProps}>
      <div className="relative flex grow flex-col gap-4">
        <div className="flex flex-col items-start justify-between rounded-md bg-slate-50 font-medium @md/card:flex-row @md/card:items-center max-md:gap-2 max-md:p-3 md:gap-2 md:p-4 ">
          <p className="text-2xs uppercase leading-none text-slate-500 @md/card:text-sm">Recommended Total Amounts</p>
          <div className="flex items-center gap-1.5 text-sm leading-none text-slate-700">
            <p># units</p>
            <div className="h-4 w-px bg-slate-900/10" />
            <p>${(0).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
          </div>
        </div>

        <div className="h-full grow">
          <CheckoutAccordion />
        </div>

        <hr className="mb-2 mt-2 border-slate-900/10 " />

        <div className="flex flex-col items-end gap-4 font-medium @md/card:flex-row @md/card:justify-between">
          <div className="flex flex-col items-start gap-1">
            <p className="text-2xs uppercase leading-none text-slate-500">Cart summary</p>
            <div className="flex items-center gap-1.5 text-sm text-sky-600">
              <p># ctns</p>
              <div className="h-4 w-px bg-slate-900/10" />
              <p>${(0).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
            </div>
          </div>

          <div className="flex shrink-0 gap-3">
            <Button secondary>Save & Close</Button>
            <PlaceOrder />
          </div>
        </div>
      </div>
    </Card>
  )
}

CartCard.propTypes = {
  span: number
}

export default CartCard
