export const common = {
  showOrderDates: true,
  dtpSku: 75106687
}

export const dev = {
  apiEndpoint: 'http://localhost:5001', // 'http://9813-96-21-62-252.ngrok.io' 'https://nod-qa-api.v7apps.com'
  siteUrl: 'http://localhost:2000',
  legacyUrl: 'https://nod-staging.v7static.com',
  customerServiceEmail: 'robynn+nod_dev@volume7.io',
  googleMapsKey: 'AIzaSyA_ZjShtywJ3N_twFmcg0mAZS5Xc2ziGF0',
  googleTagManager: null,
  ga4Property: 'G-0XR6G89GL7',
  ppScannerUrl: 'http://localhost:3000',
  rmaKitGeneratorUrl: 'https://pp-returns-staging.v7static.com/876tufyu5r64',
  extraComplianceSurvey: {
    id: 72,
    clerkEducationQuestionId: 'pdmApXiT3G6xckVc_xQzS',
    twoPackConsumerOfferQuestionId: '9FD_TDRIobh8ZjacxE5yr',
    priceCeilingsQuestionId: 'kHEnjRn7CcpNGEMNfOOWk',
    eldpQuestionId: '0UnIxldaym7EzmENy8jkf',
    volumeAndPriceCaptureQuestionId: 'BeQMFKkQH-mBfTW6fRY7Z',
    vuseAssortmenQuestionId: 'olS4O2jnEi8TleKWgyO5w',
    termsAndConditionsQuestionId: 'OlOkj50jTVW-dzzlsmMmy'
  },
  extra20PosDataSharingConditionSurvey: {
    id: 351,
    agreeToSharePOSDataQuestionId: 'hELCzdblrYGNsUYuV5om_',
    cashRegisterOrPosQuestionId: 'YTM_WwiILYjpn1Ci3VqeE',
    posDataSupplierQuestionId: 'b3VqQFAQMCm3m5H-Any2n'
  },
  extra20NationalCompliancyTrackingSurvey2023: {
    id: 350,
    extra2PackConsOfferQuestionId: 'n5ZGLULQKHBlxgWiDuZ3I',
    vuseAssortmenQuestionId: 'E73586JIp4PiyieHsnqrb',
    clerkEducationQuestionId: 'b5Tc6JoviMeo4WRJMHOoO',
    ttcEdlpQuestionId: 'YEmc8oAkSl3o68dbf3G0J'
  },
  tcpEnabled: 'ALL',
  vctmEnabled: true,
  sellInEnabled: true,
  autoSchedulingEnabled: 'ALL',
  geolockEnabledForAll: 'ALL',
  featureFlags: {
    nrtPace: false,
    pricingPace: true,
    distroPace: true,
    inventoryPace: true,
    extrahubPace: true,
    cycleFocusPace: true,
    egagement: true,
    pricingAction: true,
    i2a: true,
    closeOpportunities: true
  }
}

export const staging = {
  apiEndpoint: 'https://nod-staging-api.v7apps.com',
  siteUrl: 'https://nod-staging.v7static.com',
  legacyUrl: 'https://nod-staging.v7static.com',
  customerServiceEmail: 'nod-staging@bat.com',
  googleMapsKey: 'AIzaSyA_ZjShtywJ3N_twFmcg0mAZS5Xc2ziGF0',
  ga4Property: null,
  googleTagManager: {
    gtmId: 'GTM-K8NBF2L',
    auth: 'CfkmThnKOBcABsYRQlCk3w',
    preview: 'env-9'
  },
  ppScannerUrl: 'https://pp-returns-app-staging.netlify.app',
  rmaKitGeneratorUrl: 'https://pp-returns-staging.v7static.com/876tufyu5r64',
  extraComplianceSurvey: {
    id: 72,
    clerkEducationQuestionId: 'pdmApXiT3G6xckVc_xQzS',
    twoPackConsumerOfferQuestionId: '9FD_TDRIobh8ZjacxE5yr',
    priceCeilingsQuestionId: 'kHEnjRn7CcpNGEMNfOOWk',
    eldpQuestionId: '0UnIxldaym7EzmENy8jkf',
    volumeAndPriceCaptureQuestionId: 'BeQMFKkQH-mBfTW6fRY7Z',
    vuseAssortmenQuestionId: 'olS4O2jnEi8TleKWgyO5w',
    termsAndConditionsQuestionId: 'OlOkj50jTVW-dzzlsmMmy'
  },
  extra20PosDataSharingConditionSurvey: {
    id: null
  },
  extra20NationalCompliancyTrackingSurvey2023: {
    id: null
  },
  tcpEnabled: 'ALL',
  vctmEnabled: true,
  sellInEnabled: true,
  autoSchedulingEnabled: 'ALL',
  geolockEnabledForAll: 'ALL',
  featureFlags: {
    nrtPace: false,
    pricingPace: false,
    distroPace: false,
    inventoryPace: false,
    extrahubPace: false,
    cycleFocusPace: false,
    egagement: false,
    pricingAction: false,
    i2a: false
  }
}

export const production = {
  apiEndpoint: 'https://nod-api.wearefuturemaker.ca',
  siteUrl: 'https://nod.batapp.ca',
  legacyUrl: 'https://nod-legacy.v7static.com',
  customerServiceEmail: 'ca-ff_911@bat.com',
  googleMapsKey: 'AIzaSyA_ZjShtywJ3N_twFmcg0mAZS5Xc2ziGF0',
  googleTagManager: {
    gtmId: 'GTM-K8NBF2L',
    auth: '9S3gb9A1-uzNfD2l3rTvMw',
    preview: 'env-1'
  },
  ga4Property: 'G-6E0DHM0Q2B',
  ppScannerUrl: 'https://pp-returns-app.netlify.app',
  rmaKitGeneratorUrl: 'https://trousse-kit.v7static.com/876tufyu5r64',
  extraComplianceSurvey: {
    id: 72,
    clerkEducationQuestionId: 'pdmApXiT3G6xckVc_xQzS',
    twoPackConsumerOfferQuestionId: '9FD_TDRIobh8ZjacxE5yr',
    priceCeilingsQuestionId: 'kHEnjRn7CcpNGEMNfOOWk',
    eldpQuestionId: '0UnIxldaym7EzmENy8jkf',
    volumeAndPriceCaptureQuestionId: 'BeQMFKkQH-mBfTW6fRY7Z',
    vuseAssortmenQuestionId: 'olS4O2jnEi8TleKWgyO5w',
    termsAndConditionsQuestionId: 'OlOkj50jTVW-dzzlsmMmy'
  },
  extra20PosDataSharingConditionSurvey: {
    id: 351,
    agreeToSharePOSDataQuestionId: 'hELCzdblrYGNsUYuV5om_',
    cashRegisterOrPosQuestionId: 'YTM_WwiILYjpn1Ci3VqeE',
    posDataSupplierQuestionId: 'b3VqQFAQMCm3m5H-Any2n'
  },
  extra20NationalCompliancyTrackingSurvey2023: {
    id: 350,
    extra2PackConsOfferQuestionId: 'n5ZGLULQKHBlxgWiDuZ3I',
    vuseAssortmenQuestionId: 'E73586JIp4PiyieHsnqrb',
    clerkEducationQuestionId: 'b5Tc6JoviMeo4WRJMHOoO',
    ttcEdlpQuestionId: 'YEmc8oAkSl3o68dbf3G0J'
  },
  tcpEnabled: 'ALL',
  vctmEnabled: true,
  sellInEnabled: true,
  autoSchedulingEnabled: 'ALL',
  geolockEnabledForAll: 'ALL',
  featureFlags: {
    nrtPace: false,
    pricingPace: true,
    distroPace: true,
    inventoryPace: true,
    extrahubPace: true,
    cycleFocusPace: true,
    egagement: true,
    pricingAction: true,
    i2a: true
  }
}

export const qa = {
  apiEndpoint: 'https://nod-adv-api.v7apps.com',
  siteUrl: 'https://nod-qa.v7static.com',
  legacyUrl: 'https://nod-staging.v7static.com',
  customerServiceEmail: 'robynn+qa_nod_customer_service@volume7.io',
  googleMapsKey: 'AIzaSyA_ZjShtywJ3N_twFmcg0mAZS5Xc2ziGF0',
  googleTagManager: null,
  ga4Property: 'G-0XR6G89GL7',
  ppScannerUrl: 'https://pp-returns-app-qa.netlify.app',
  rmaKitGeneratorUrl: 'https://pp-returns-qa.v7static.com/876tufyu5r64',
  extraComplianceSurvey: {
    id: 54,
    clerkEducationQuestionId: 'quQl6UmquAwMTNQYflJFM',
    twoPackConsumerOfferQuestionId: 't2_BGrXOHnB0ZqGUv_log',
    priceCeilingsQuestionId: 'PBRKTy7boAwTyfxDvOuKL',
    eldpQuestionId: 'ULqEWdlhfcOQXEhSx2hXr',
    volumeAndPriceCaptureQuestionId: 'kpH7JMMG9_L0nEXoh4-pz',
    vuseAssortmenQuestionId: 'fUC5quI9YwEs0ZlsFWzv3',
    termsAndConditionsQuestionId: '4YqGC6V7tqixI_jCam-1h'
  },
  extra20PosDataSharingConditionSurvey: {
    id: 351,
    agreeToSharePOSDataQuestionId: 'hELCzdblrYGNsUYuV5om_',
    cashRegisterOrPosQuestionId: 'YTM_WwiILYjpn1Ci3VqeE',
    posDataSupplierQuestionId: 'b3VqQFAQMCm3m5H-Any2n'
  },
  extra20NationalCompliancyTrackingSurvey2023: {
    id: 350,
    extra2PackConsOfferQuestionId: 'n5ZGLULQKHBlxgWiDuZ3I',
    vuseAssortmenQuestionId: 'E73586JIp4PiyieHsnqrb',
    clerkEducationQuestionId: 'b5Tc6JoviMeo4WRJMHOoO',
    ttcEdlpQuestionId: 'YEmc8oAkSl3o68dbf3G0J'
  },
  tcpEnabled: 'ALL',
  vctmEnabled: true,
  sellInEnabled: true,
  autoSchedulingEnabled: 'ALL',
  geolockEnabledForAll: 'ALL',
  featureFlags: {
    nrtPace: false,
    pricingPace: true,
    distroPace: true,
    inventoryPace: true,
    extrahubPace: true,
    cycleFocusPace: true,
    egagement: true,
    pricingAction: true,
    i2a: true,
    closeOpportunities: true
  }
}

export const legacy = {
  apiEndpoint: 'https://nod-api.wearefuturemaker.ca',
  siteUrl: 'https://nod-legacy.v7static.com',
  customerServiceEmail: 'ca-ff_911@bat.com',
  googleMapsKey: 'AIzaSyA_ZjShtywJ3N_twFmcg0mAZS5Xc2ziGF0',
  googleTagManager: {
    gtmId: 'GTM-K8NBF2L',
    auth: '9S3gb9A1-uzNfD2l3rTvMw',
    preview: 'env-1'
  },
  ppScannerUrl: 'https://pp-returns-app.netlify.app',
  rmaKitGeneratorUrl: 'https://trousse-kit.v7static.com/876tufyu5r64',
  extraComplianceSurvey: {
    id: 72,
    clerkEducationQuestionId: 'pdmApXiT3G6xckVc_xQzS',
    twoPackConsumerOfferQuestionId: '9FD_TDRIobh8ZjacxE5yr',
    priceCeilingsQuestionId: 'kHEnjRn7CcpNGEMNfOOWk',
    eldpQuestionId: '0UnIxldaym7EzmENy8jkf',
    volumeAndPriceCaptureQuestionId: 'BeQMFKkQH-mBfTW6fRY7Z',
    vuseAssortmenQuestionId: 'olS4O2jnEi8TleKWgyO5w',
    termsAndConditionsQuestionId: 'OlOkj50jTVW-dzzlsmMmy'
  },
  extra20PosDataSharingConditionSurvey: {
    id: 351,
    agreeToSharePOSDataQuestionId: 'hELCzdblrYGNsUYuV5om_',
    cashRegisterOrPosQuestionId: 'YTM_WwiILYjpn1Ci3VqeE',
    posDataSupplierQuestionId: 'b3VqQFAQMCm3m5H-Any2n'
  },
  extra20NationalCompliancyTrackingSurvey2023: {
    id: 350,
    extra2PackConsOfferQuestionId: 'n5ZGLULQKHBlxgWiDuZ3I',
    vuseAssortmenQuestionId: 'E73586JIp4PiyieHsnqrb',
    clerkEducationQuestionId: 'b5Tc6JoviMeo4WRJMHOoO',
    ttcEdlpQuestionId: 'YEmc8oAkSl3o68dbf3G0J'
  },
  tcpEnabled: 'ALL',
  vctmEnabled: true,
  sellInEnabled: true,
  autoSchedulingEnabled: 'ALL',
  geolockEnabledForAll: 'ALL',
  featureFlags: {
    nrtPace: false,
    pricingPace: false,
    distroPace: false,
    inventoryPace: false,
    extrahubPace: false,
    cycleFocusPace: false,
    egagement: false,
    pricingAction: false,
    i2a: false
  }
}
