import React, { useContext, useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import { bool, func, number } from 'prop-types'

import LangContext from 'context/LangContext'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Input from 'components/Input'
import { ToggleField } from 'components/Toggle'

import { required } from 'utils/validators'

export const PO98Content = ({ isMobileViewOnly, totalQuantity }) => {
  const { translate } = useContext(LangContext)
  const formikContext = useFormikContext()

  const totalQuantityNotReached = totalQuantity < 5

  const coordinatorUpdated =
    formikContext.values.po98CoordinatorApproved && formikContext.values.po98CoordinatorName?.trim().length > 0

  useEffect(() => {
    if (formikContext.values.po98CoordinatorApproved) formikContext.setFieldTouched('po98CoordinatorName', true)
    if (!formikContext.values.po98CoordinatorApproved) formikContext.setFieldValue('po98CoordinatorName', '')
  }, [formikContext.values.po98CoordinatorApproved, coordinatorUpdated])

  const submitIsEnabled =
    !totalQuantityNotReached &&
    Object.keys(formikContext.errors).length === 0 &&
    !formikContext.isSubmitting &&
    !isMobileViewOnly

  // if changing any of the values, remember to change them in the BE too !
  const OPTIONS = [
    {
      label: translate('po98.outOfStock'),
      value: 'outOfStock'
    },
    {
      label: translate('po98.lowInventory'),
      value: 'lowInventory'
    },
    {
      label: translate('po98.missedOrder'),
      value: 'missedOrder'
    },
    {
      label: translate('po98.distributionGap'),
      value: 'distributionGap'
    },
    {
      label: translate('po98.firstOrder'),
      value: 'firstOrder'
    }
  ]

  const YES_NO = [
    {
      label: translate('common.yes'),
      value: true
    },
    {
      label: translate('common.no'),
      value: false
    }
  ]

  const handleDropDown = (event) => {
    const status = event.target.value === 'true'

    formikContext.setFieldValue('po98CoordinatorApproved', status)
  }

  return (
    <Fieldset>
      <FieldsetItem>
        <Field
          component={Dropdown}
          options={OPTIONS}
          name="po98Reason"
          label={translate('po98.reason')}
          menuPlacement="top"
          placeholder={translate('po98.selectOption')}
          validate={required}
        />
      </FieldsetItem>
      <FieldsetItem>
        <Field
          component={Dropdown}
          options={YES_NO}
          name="po98CoordinatorApproved"
          label={translate('po98.isApprovedBy')}
          menuPlacement="top"
          onChange={handleDropDown}
          placeholder={translate('po98.selectOption')}
          validate={required}
        />
      </FieldsetItem>
      {formikContext.values.po98CoordinatorApproved === 'true' && (
        <FieldsetItem>
          <Field
            component={Input}
            name="po98CoordinatorName"
            label={translate('po98.insertCoordonatorName')}
            validate={required}
          />
        </FieldsetItem>
      )}
      <FieldsetItem>
        <Field component={Input} name="po98Comments" label={translate('po98.comments')} textarea />
      </FieldsetItem>
      <FieldsetItem>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{translate('po98.isEmergencyOrder')}</span>
          <Field component={ToggleField} name="emergencyFlag" />
        </div>
      </FieldsetItem>
      <FieldsetItem>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{translate('po98.sendEmailCopy')}</span>
          <Field component={ToggleField} name="sendEmailCopy" isDisabled />
        </div>
      </FieldsetItem>
      {totalQuantityNotReached && (
        <FieldsetItem>
          <GlobalAlert warning>
            <p>{translate('po98.minimumQty')}</p>
          </GlobalAlert>
        </FieldsetItem>
      )}
      <FieldsetItem>
        <Button
          onClick={formikContext.submitForm}
          type="submit"
          primary
          full
          disabled={formikContext.isSubmitting || !submitIsEnabled}
        >
          {translate('submit')}
        </Button>
      </FieldsetItem>
    </Fieldset>
  )
}

PO98Content.propTypes = {
  isMobileViewOnly: bool,
  totalQuantity: number
}

function PO98ActionSheet({ isMobileViewOnly, onClose, isEdit, totalQuantity }) {
  const { translate } = useContext(LangContext)

  const actionTitle = isEdit ? 'po98.editOrder' : 'po98.createOrder'

  return (
    <ActionSheet
      title={translate(actionTitle)}
      visible
      action={
        <button type="button" onClick={onClose}>
          <Icon icon="close" />
        </button>
      }
      onOverlayClick={onClose}
    >
      <PO98Content
        isMobileViewOnly={isMobileViewOnly}
        onClose={onClose}
        isEdit={isEdit}
        totalQuantity={totalQuantity}
      />
    </ActionSheet>
  )
}

PO98ActionSheet.propTypes = {
  isMobileViewOnly: bool,
  onClose: func.isRequired,
  isEdit: bool,
  totalQuantity: number
}

export default PO98ActionSheet
