import React, { useContext } from 'react'
import { bool, func, node, number, object, oneOf, string } from 'prop-types'
import tw, { css, styled } from 'twin.macro'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import IconButton from 'components/button/IconButton'
import Icon from 'components/Icon'
import TooltipText from 'components/TooltipText'

import AlertProvider from '../AlertProvider'

const Amplify = ({ size, amplifyAction }) => {
  const { translate } = useContext(LangContext)

  if (size <= 2)
    return (
      <IconButton secondary icon="three-stars" onClick={amplifyAction}>
        {translate('app.pace.amplify')}
      </IconButton>
    )
  else
    return (
      <Button secondary icon="three-stars" onClick={amplifyAction}>
        {translate('app.pace.amplify')}
      </Button>
    )
}

Amplify.propTypes = {
  size: number,
  amplifyAction: func
}

export const Controls = styled.div((props) => [tw`grid gap-4`, props.twocols && tw`grid-cols-2`])

export const DataLabel = tw.span`block text-2xs font-medium text-slate-500`

export const DataXL = tw.span`block text-xl font-medium text-slate-900`

export const Data2XL = tw.span`block text-3xl font-medium text-slate-900`

const ResponsiveContainer = styled.div`
  display: grid;
  gap: 16px;

  ${({ hideOnSmallScreens }) => css`
    @media (max-width: 1200px) {
      display: ${hideOnSmallScreens ? 'none' : 'grid'};
    }
  `}

  ${({ span }) => css`
    grid-column: span ${span.xs} / span ${span.xs};
  `}

  ${({ span }) => css`
    @media (min-width: 600px) {
      grid-column: span ${span.sm} / span ${span.sm};
    }
  `}

  ${({ span }) => css`
    @media (min-width: 900px) {
      grid-column: span ${span.md} / span ${span.md};
    }
  `}

  ${({ span }) => css`
    @media (min-width: 1200px) {
      grid-column: span ${span.lg} / span ${span.lg};
    }
  `}

  ${({ span }) => css`
    @media (min-width: 1600px) {
      grid-column: span ${span.xl} / span ${span.xl};
    }
  `}
`

const Container = styled.div((props) => [
  props.backgroundColor === 'white' ? tw`bg-white shadow-md shadow-brand-900/10` : tw`bg-transparent`
])

const Title = tw.span`inline-flex items-center text-left h-9 text-xl font-semibold text-slate-900`

const Content = tw.div`grow flex flex-col gap-4 max-w-full`

const ActionsContainer = tw.div`w-full max-w-6xl max-md:justify-start justify-end flex-wrap flex gap-3`

const Card = ({
  title,
  customTitle,
  actions,
  headerActions,
  amplifyAction,
  children,
  span,
  amplifySize,
  hideOnSmallScreens,
  alertIsVisible,
  alertMessage,
  removeHeader,
  backgroundColor = 'white',
  disclaimer
}) => {
  return (
    <ResponsiveContainer span={span} hideOnSmallScreens={hideOnSmallScreens}>
      <AlertProvider displayAlert={alertIsVisible} alertMessage={alertMessage}>
        <Container
          backgroundColor={backgroundColor}
          className="flex h-full flex-col flex-wrap gap-6 rounded-md p-6 @container/card"
        >
          {!removeHeader && (
            <div className="flex items-center justify-between max-md:flex-col max-md:items-start max-md:gap-2">
              {customTitle ? (
                <div>{customTitle}</div>
              ) : (
                <Title>
                  {title}
                  {disclaimer && (
                    <TooltipText hint={disclaimer}>
                      <Icon icon="info-outline" />
                    </TooltipText>
                  )}
                </Title>
              )}
              <div className="space-x-3 max-md:w-full">
                {headerActions && (
                  <ActionsContainer>
                    {headerActions.map((action, i) => (
                      <div className="w-full md:min-w-min" key={i}>
                        {action}
                      </div>
                    ))}
                  </ActionsContainer>
                )}
                {amplifyAction && <Amplify size={amplifySize} amplifyAction={amplifyAction} />}
              </div>
            </div>
          )}
          <Content>{children}</Content>
          {actions && (
            <ActionsContainer>
              {actions.map((action, i) => (
                <div className="w-full" key={i}>
                  {action}
                </div>
              ))}
            </ActionsContainer>
          )}
        </Container>
      </AlertProvider>
    </ResponsiveContainer>
  )
}

Card.propTypes = {
  children: node.isRequired,
  actions: node,
  headerActions: node,
  span: object,
  title: string,
  customTitle: object,
  amplifyAction: func,
  amplifySize: number,
  hideOnSmallScreens: bool,
  alertIsVisible: bool,
  removeHeader: bool,
  alertMessage: string,
  backgroundColor: oneOf(['white', 'transparent']),
  disclaimer: string
}

export default Card
